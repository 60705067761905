.left_p,
.center_p,
.right_p {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}
.left_p,
.right_p {
  border: 1px solid #d0d5dd;
  padding: 8px 14px;
  border-radius: 8px;
  background-color: transparent;
}
.right_p {
  float: right;
  cursor: pointer;
}
.center_p {
  text-align: center;
  border: none;
  padding: 8px 14px;
}
.left_p {
  float: left;
  cursor: pointer;
}
.page_c {
  text-align: center;
}
.active {
  background-color: #f0f3fe;
  border-radius: 8px;
  color: black;
  font-weight: 500;
}
.active2 {
  background-color: transparent;
  color: #667085;
  font-weight: 500;
}
.vw-33{
    width: 33%;
}
.vw-20{
    width: 27%;
}
.vw-60{
    width: 50%;
}
@media (min-width: 768px) and (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 426px) {
  .left_p,
.center_p,
.right_p {
font-size: 6px;
}
.left_p,
.right_p {
padding: 3px 5px;
}
.page_c {
  overflow-x: auto;
}
.center_p {
  padding: 3px 5px;
}
}

@media (max-width: 440px) {
  .left_p,
.center_p,
.right_p {
font-size: 14px;
}
.left_p,
.right_p {
padding: 3px 5px;
}
.page_c {
  overflow-x: auto;
}
.center_p {
  padding: 3px 5px;
}
}
