.vh-100 {
  height: 100vh;
}
.vw-100{
  width: 100%;
}
.main-div {
  width: 320px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.arrow-left{
  cursor: pointer;
}
.verify-otp-text {
  font-size: 18px;
  font-weight: 600;
  color: #144936;
  font-family: "Poppins", sans-serif;
}
.description-text {
  text-align: center;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #878787;
  margin: 10px 0 10px 0;
}
.otp-show-div {
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.verify-otp-div {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* 142.857% */
}
.verify-otp-div-text {
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  margin: 15px 0 10px 0;
  /* 142.857% */
}
.otp-expired-time-text {
  color: #ff0000;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.otp-email-des-text {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.otp-email-text {
  color: #ff0000;
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  padding-left: 5px;
}
.otp-send-text {
  cursor: pointer;
  color: #0075ff;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin: 15px 0 10px 0;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: to slide in the button */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shine {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6; /* Adjust opacity to make it look disabled */
}

.disabled .otp-input-field,
.disabled button {
  pointer-events: none;
  opacity: 0.6; /* Adjust opacity to make it look disabled */
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as needed */
}

.loader .bubble {
  width: 15px;
  height: 15px;
  margin: 3px;
  background-color: #32f79e; /* Adjust color as needed */
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.5s infinite ease-in-out;
}

.loader .bubble:nth-child(2) {
  animation-delay: 0.3s;
}

.loader .bubble:nth-child(3) {
  animation-delay: 0.6s;
}