.vh-100 {
    height: 100vh;
}

.main-div{
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center ;
    padding: 15px;
}


.create-account-text{
    font-size: 18px;
    font-weight: 600;
    color: #144936;
    font-family: 'Poppins', sans-serif;
}

.description-text{
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #878787;
    margin: 10px 0 10px 0;
}
.email-label-div-text {
    width: 100%;
    color: #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    margin: 15px 0 10px 0;
    /* 142.857% */
  }
  .email-div-type {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--White, #fff);
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 5px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .email-div-type-input {
    width: 100%;
    border: none;
    outline: none;
    align-items: start;
    text-align: left;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);
    /* background: lightgreen; */
    /* padding-top: 10px;*/
    padding-left: 10px; 
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .verified-background {
    background-color: #32f79e; /* or any color you prefer */
  }
  
  .unverified-background {
    background-color: #ff0000ab; /* or any color you prefer */
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px); /* Optional: to slide in the button */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes shine {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  .sign-in-button {
    opacity: 0; /* Initial state */
    animation: fadeIn 2s forwards; /* 2 seconds duration */
    transition: transform 0.3s ease; /* Smooth transition for the hover effect */
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
  
  .sign-in-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    background-size: 200% 100%;
    z-index: -1;
    transition: opacity 0.3s ease;
    opacity: 0;
  }
  
  .sign-in-button:hover::before {
    opacity: 1;
    animation: shine 1s linear infinite;
    z-index: 1;
  }
  
  .already-have-text{
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: #9500CA;
    margin: 25px 0 10px 0;
  }
  .login-text{
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: #9500CA;
    margin: 25px 0 10px 0;
    cursor: pointer;
  }
  .email-invalid-text{
    color: red;
    font-size: 10px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    text-align: start;
    width: 100%;
    padding: 5px 5px 5px 5px;
  }
  @keyframes bounce {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust as needed */
  }
  
  .loader .bubble {
    width: 15px;
    height: 15px;
    margin: 3px;
    background-color: #32f79e; /* Adjust color as needed */
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .loader .bubble:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .loader .bubble:nth-child(3) {
    animation-delay: 0.6s;
  }