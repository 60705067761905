
.vw-100 {
  width: 100%;
}
.main-invite-div2 {
  height: 90vh !important;
  width: 68%;
  padding: 30px 0 20px 0;
}
@media (max-width: 432px) {
  .main-invite-div2 {
    width: 90%;
  }
}

.invite-friend-text {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.invite-friend-text-sub {
  color: #878787;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.copy-url-text {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.invite-link-div {
  max-width: 335px;
  width: 100%;
  background-color: #f5f8ff;
  border-radius: 8px;
  height: 56px;
  padding: 16px;
  border: 0.7px solid #abc9fc;
}

.link-text {
  color: #0059f4;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.earn-desc {
  text-align: start !important;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  max-width: 335px;
  width: 100%;
}
.div-border {
  max-width: 335px;
  width: 100%;
  border: 0.8px solid #ebebeb;
  margin: 15px 0 15px 0;
}
.your-earing-text {
  text-align: start !important;
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  max-width: 335px;
  width: 100%;
}
.points-div {
  max-width: 335px;
  width: 100%;
}
.points-value {
  color: #000000;
  font-size: 36px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  line-height: 24px;
}
.points-text {
  text-align: center !important;
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  line-height: 24px;
}
.transfer-text {
  color: #007942;
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin: 0 5px 0 5px;
}
.warning-text {
  text-align: start !important;
  color: #ff6363;
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  max-width: 335px;
  width: 100%;
}
