.vh-100 {
  height: 100%;
}
.vh-50 {
  height: 50%;
}
.vh-30 {
  height: 30%;
}
.vh-20 {
  height: 20%;
}
.vw-100 {
  width: 100%;
}
.main-payment-div {
  height: 100vh;
  width: 68%;
  padding: 30px 0 20px 0;
}
@media (max-width: 432px) {
  .main-payment-div {
    width: 90%;
  }
}
.topic {
  color: #144936;
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  margin: 10px 0 2px 0;
}
.sub-topic {
  color: #878787;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}
.no-data-text {
  color: #878787;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  margin-bottom: 15px;
}
.list-div {
  margin-bottom: 30px;
  /* height: 68vh; */
  overflow: hidden;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0px;
}

.card {
  background-color: #fbfbfb;
  width: 100%;
  min-height: 111px;
  height: auto;
  border-radius: 10px;
  padding: 10px 10px 10px 13px;
}
.date-text {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.download-recipt-text {
  color: #ff0000;
  font-weight: 500;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.details-div {
  width: 85%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19%, 5fr));
}
.details-div2 {
  width: 95%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15%, 6fr));
}
@media (min-width: 768px) and (max-width: 1024px) {
  .details-div {
    width: 80%;
  }
  .details-div2 {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .details-div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 2fr));
    gap: 10px;
  }
  .details-div2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 2fr));
    gap: 10px;
  }
  .details-topic-text {
    font-size: 10px;
  }
  .details-sub-topic-text {
    font-size: 10px;
  }
}
@media (max-width: 432px) {
  .details-div {
    width: 100%;
  }
  .details-topic-text {
    font-size: 13px !important;
  }
  .details-sub-topic-text {
    font-size: 11px !important;
  }
}
@media (max-width: 375px) {
  .details-div {
    width: 100%;
  }
  .details-div2 {
    width: 100%;
  }
  .details-topic-text {
    font-size: 10px !important;
  }
  .details-sub-topic-text {
    font-size: 10px !important;
  }
}
.details-sub-div {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.details-topic-text {
  color: #979595;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.details-sub-topic-text {
  color: #000000;
  font-weight: 530;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

/* Loader CSS */
@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as needed */
}

.loader .bubble {
  width: 15px;
  height: 15px;
  margin: 3px;
  background-color: #32f79e; /* Adjust color as needed */
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.5s infinite ease-in-out;
}

.loader .bubble:nth-child(2) {
  animation-delay: 0.3s;
}

.loader .bubble:nth-child(3) {
  animation-delay: 0.6s;
}
.navbar-send-money-btn2 {
  border: 1px solid #008b37;
  background-color: #000000;
  min-width: 200px;
  width: 100%;
  height: 44px !important;
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
}
.promotion-btn-div2 {
  display: none;
}
.invite-div1{
  display: none;
}
@media (max-width: 768px) {
  .promotion-btn-div2 {
    display: block;
    text-decoration: none;
  }
  .invite-div1{
    display: block !important;
    text-decoration: none;
  }
}
.formik-errors {
  color: #ff0000;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.offcanvas-label-div-text {
  width: 100%;
  color: #27272a;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 15px 0 2px 0;
  /* 142.857% */
}
.canvas-sub-topic-text {
  color: #64748b;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.add-button {
  width: 145px;
  height: 44px;
  border-radius: 8px;
  padding: 8px;
  background-color: #144936;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.transaction-text {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}
@media (max-width: 768px) {
  .promotion-btn-div {
    display: none;
  }

  .invite-div2{
    display: none
  }
}
.navbar-send-money-btn {
  border: 1px solid #008b37;
  background-color: #000000;
  width: auto !important;
  height: 44px !important;
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
}
.navbar-send-money-btn3 {
  border: 1px solid #008b37;
  background-color: #000000;
  width: auto;
  height: 44px !important;
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}
.add-btn-display {
  display: block;
}

@media (max-width: 768px) {
  .add-btn-display {
    display: none;
  }
}

.add-button2 {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  padding: 8px;
  background-color: #144936;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 768px) {
  .add-btn-display2 {
    display: block !important;
    width: 100%;
  }
}
.add-btn-display2 {
  display: none;
}

.card-div{
  min-height: 86px;
  width: 100%;
  height: auto;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  background-color: #FFFFFF;
  padding: 10px;
}

.card-number-text{
  color: #3C3C3C;
  font-weight: 500;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}
.expire-date{
  color: #5E5E5E;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.holder-name{
  color: #757575;
  font-weight: 500;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.default-card{
  background-color: #FCFCD9;
  border-radius: 4px;
  padding: 2px 6px 2px 6px;
  color: #987600;
  font-weight: 500;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
}
.card-grid{
  display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 3fr));
    gap: 10px;
}
@media (max-width: 768px) {
  .card-grid{
    display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      gap: 10px;
  }
  .no-card-for-add-btn{
    display: none !important;
  }
}
.no-card-for-add-btn{
  display: block;
}
.no-card-option-div{
  position: relative;
}
.no-card-option{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  
}
.no-card-option-text{
  width: 100%;
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.iframe-container {
  /* position: relative; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.iframe {
  width: 100%;
  height: 100%;
  /* z-index: 3; */
}
.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#threedsChallengeRedirectForm{
  background-color: #008b37;
  font-size: 75px;
}
.disabled-area {
  pointer-events: none;
  color: gray;
  opacity: 50%;
  /* Any other styles you want to apply to disabled links */
}
.sercure-by-div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.sercure-by-div-text{
  color: #898989;
  font-weight: 500;
  font-size: 10px;
  font-family: "Inter", sans-serif;
}
.select-transaction-div{
  width: auto;
  height: 37px;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  background-color: #E6FBE2;
  color: #355030;
  font-weight: 500;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}
.unselect-transaction-div{
  width: auto;
  height: 37px;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
  color: #355030;
  font-weight: 500;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}
.invite-friend-div{
  width: 100%;
height: 61px;
padding: 6px;
gap: 10px;
border-radius: 8px;
display: flex;
justify-content: space-between;
align-items: center;
background-color: #FFDEC5 !important;
}
.invite_text{
  font-weight: 600;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: #E1631F !important;
}
.award_text{
  font-weight: 500;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: #333333
}
.succes-trans{
  width: auto;
height: auto;
left: 1078px;
padding: 5px 10px 5px 10px;
gap: 10px;
border-radius: 8px 8px 8px 8px;
background-color: #B5FFBC;
font-weight: 600;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #00900A;
}
.failed-trans{
  width: auto;
height: auto;
left: 1078px;
padding: 5px 10px 5px 10px;
gap: 10px;
border-radius: 8px 8px 8px 8px;
background-color: #d31111;
font-weight: 600;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #ffffff;
}
.card-delete-btn{
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  background-color: #d31111;
  color: #effbf7;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}