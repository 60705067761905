/* OffCanvasView.css */

.off-canvas-overlay-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.off-canvas-view {
  position: fixed;
  top: 0;
  right: -415px;
  width: 415px;
  height: 100%;
  background-color: #ffffff;
  transition: right 0.8s ease-in-out;
  overflow-y: auto;
  scrollbar-width: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  z-index: 1;
}

.off-canvas-view.open-view {
  right: 0;
}

.off-canvas-content-view {
  padding: 10px;
}

.close-button-view {
  display: block;
  margin-bottom: 10px;
}

.tittle {
  /* font-family: 'Inter'; */
  color: #18181B;

  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;;
  line-height: 28px; /* 155.556% */
}

.close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}


@media (max-width: 432px) {
  .off-canvas-view {
    position: fixed;
    top: 0;
    right: -415px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    transition: right 0.8s ease-in-out;
    overflow-y: auto;
    scrollbar-width: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    z-index: 1;
  }
}