
.vw-100 {
  width: 100%;
}
.vw-70 {
  width: 60%;
}
.vw-30 {
  width: 30%;
}
.div-width {
  max-width: 335px;
  width: 100%;
}
.main-invite-div3 {
  height: 100vh;
  width: 68%;
  padding: 30px 0 20px 0;
}
@media (max-width: 432px) {
  .main-invite-div3 {
    width: 90%;
  }
}
.points-value {
  color: #000000;
  font-size: 36px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  line-height: 24px;
}
.points-text {
  text-align: center !important;
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  line-height: 24px;
}
.div-border {
  max-width: 335px;
  width: 100%;
  border: 0.8px solid #ebebeb;
  margin: 15px 0 15px 0;
}
.point-topic {
  color: #144936;
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.point-sub-topic {
  color: #878787;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.point-amount-text {
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.curency-rate {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.transfer-button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background-color: #144936;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  margin: 25px 0 10px 0;
}
