/* OTPInput.css */
.otp-input {
    display: flex;
    justify-content: space-between;
  }
  
  .otp-input-field {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  