/* ToggleButton.css */
.toggle-button {
    width: 34px;
    height: 19px;
    border-radius: 24px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .toggle-button.toggled {
    background-color: #144936;
  }
  
  .toggle-button-thumb {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s;
  }
  
  .toggle-button.toggled .toggle-button-thumb {
    left: 16px;
  }
  