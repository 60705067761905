.vw-100{
    width: 100%;
}
.vw-60{
    width: 60%;
}
.vw-40{
    width: 45%;
}
.confirmation-dialog-app5 {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 600px;
    width: 95%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 15px 10px 15px 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    z-index: 3;
    text-align: center;
    opacity: 0;
  }
  
  .confirmation-dialog-app5.show {
    opacity: 1;
  }
  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.7
    ); /* Change the background color to black with opacity */
    z-index: 2;
  }
  .view-details{
    font-family: "Inter", sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 36.36px;

  }
  .details-topic{
    color: #000000;
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 28px; /* 155.556% */
    font-family: "Inter", sans-serif;
  }
  .details-sub-topic1{
    color:#3F3F3F;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 28px; /* 155.556% */
    font-family: "Inter", sans-serif;
    text-align: left;
  }

  .details-sub-topic2{
    color:#3F3F3F;
    font-size: 16px;
    font-weight: 600 !important;
    line-height: 28px; /* 155.556% */
    font-family: "Inter", sans-serif;
    text-align: left;
  }