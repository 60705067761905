.profile-container {
    position: relative;
    display: inline-block;
  }
  .profile-icon {
    cursor: pointer;
  }
  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #FFFFFF;
    
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    box-shadow: 0px 4px 4px 0px #00000026;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%; /* Place the tooltip above the icon */
    left: 0;
    margin-top: 5px;
    margin-left: -70px; /* Center the tooltip */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltip-text-div{
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    padding: 10px 0 5px 5px;
    cursor: pointer;
  }
  
  .tooltip.show {
    visibility: visible;
    opacity: 1;
  }
  .navbar-send-money-btn{
    
    border: 1px solid #008B37;
    background-color: #000000;
    width: 150px;
    height: 35px;
    padding: 4px 10px 4px 10px;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight:600;
    font-family: "Inter", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right:10px ;
    cursor: pointer;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .promotion-btn-div{
      display: none;
    }
  }