.main-money-transfer-div {
    height: 90vh;
    width: 70%;
    padding: 30px 0 50px 0;
  }
  @media (max-width: 432px) {
    .main-money-transfer-div {
      width: 90%;
    }
  }
.money-transfer-main-div {
  width: 320px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 432px) {
    .money-transfer-main-div {
      width: 100%;
    }
  }
.vh-100 {
  height: 100vh;
}
.vw-100 {
  width: 100%;
}

.arrow-left {
  cursor: pointer;
}
.favourite-topic {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .favourite-sub-topic {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    width:100%;
    
  }
  .favourite-card{
    background-color: #F9F9F9;
    border: 1px solid #DFDFDF;
    width: 100%;
    height: 76px;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  .favourite-name{
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    padding-bottom: 2px;
  }
  .favourite-details{
    color: #595959;
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
  .scroll-container {
    max-height: 400px; /* Set the max height for the scrollable area */
    overflow-y: auto;  /* Enable vertical scrolling */
         /* Optional padding */
    border: none; /* Optional border */
    margin: 20px 0;    /* Optional margin */
    scrollbar-width: 10px;
    scrollbar-color: #595959;
}