/* Footer.css */
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    font-size: 12px;
    color: #A6A6A6;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
  