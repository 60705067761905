/* .confirmation-dialog-app {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    z-index: 3;
    text-align: center;
  } */
  .confirmation-dialog-app {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    z-index: 3;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .confirmation-dialog-app.show {
    opacity: 1;
  }
  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.7
    ); /* Change the background color to black with opacity */
    z-index: 2;
  }
  .feature-icon-update{
    width: 48px!important;
    height: auto;
  }
  .topic-app {
    margin-top: 20px;
    color: #18181b;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }
  .confirmation-dialog-app p {
    color: #52525b;
    text-align: center;
  
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .buttons-container-app {
    margin-top: 32px;
  }
  .delete-button-app {
    padding: 10px 18px 10px 18px;
    margin-right: 10px;
    width: 170px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    background-color: #d92d20;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .button-cansel-app {
    padding: 10px 18px 10px 18px;
    margin-right: 10px;
    width: 170px;
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    color: #344054;
    background-color: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    cursor: pointer;
  }
  .delete-button-app:hover {
    background-color: #d92d20 !important;
  }
  .button-cancel-app {
    padding: 10px 18px 10px 18px;
    margin-right: 10px;
    width: 170px;
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    color: #344054;
    background-color: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    cursor: pointer;
  }
  .disabled-area {
    pointer-events: none;
    color: gray;
    opacity: 50%;
    /* Any other styles you want to apply to disabled links */
  }
  