.main-profile-div {
  height: 90vh;
  width: 70%;
  padding: 30px 0 50px 0;
}
@media (max-width: 432px) {
  .main-profile-div {
    width: 90%;
  }
}
.vw-100 {
  width: 100%;
}
.second-main-div {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
@media (max-width: 432px) {
  .second-main-div {
    width: 100%;
  }
}
.oneID-profile-text {
  color: #144936;
  font-weight: 700;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.cancel-btn {
  min-width: 88px;
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  background-color: #effbf7;
  color: #144936;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #144936;
  cursor: pointer;
}
.save-btn {
  min-width: 88px;
  margin-left: 5px;
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  background-color: #144936;
  color: #effbf7;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
@media (max-width: 432px) {
  .button-div {
   display: none;
  }
  .cancel-btn{
    width: 50% !important;
  }
  .save-btn{
    width: 50% !important;
  }
}
.button-div2{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0 10px 0;
}
@media (min-width: 432px) {
  .button-div2 {
   display: none;
  }
  
}
.oneID-profile-sub-text {
  color: #878787;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.profile-details-text {
  color: #000000;
  font-weight: 700;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.coming-soon-text{
  color: #06421a;
  background-color: #4fec83f5;
  padding: 3px 5px 3px 5px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  margin-left: 5px;
}
.div-type-input {
  outline: none;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  /* background: lightgreen; */
  /* padding-top: 10px;*/
  padding: 10px; 
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  -webkit-appearance: none; /* Remove default styling in Safari */
  -moz-appearance: none;
  appearance: none;
}
.form-div{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 2fr));
  gap: 10px;
}
@media (max-width: 768px) {
  .form-div{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(95%, 1fr));
    gap: 10px;
  }
}

.toggle-div{
  margin:10px 0 10px 0 ;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(46%, 2fr));
  gap: 10px;
}
.toggle-text{
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.password-reset-text{
  color: #FF0000;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  margin:10px 0 10px 0 ;
  cursor: pointer;
}
.formik-errors{
  color: #FF0000;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.canvas-sub-title{
  color: #64748B;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}
.email-label-div-text {
  width: 100%;
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter', sans-serif;
  margin: 15px 0 10px 0;
  /* 142.857% */
}
.offcanvas-label-div-text{
  width: 100%;
  color: #27272A;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 15px 0 10px 0;
  /* 142.857% */
}
.password-reset-btn {
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  background-color: #144936;
  color: #effbf7;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.div-type-input2 {
  width: 100%;
  border: none;
  outline: none;
  align-items: start;
  text-align: left;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  /* background: lightgreen; */
  /* padding-top: 10px;*/
  padding-left: 10px; 
  font-size: 14px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.bot-name-div-type-input {
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.profile-photo-div{
  position: relative;
  width: 66px;
  height: 66px;
  border: 2px solid #EEEEEE;
  border-radius: 50%;
}
.photo-container {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* to ensure the image does not overflow the container */
}

.photo-container-img2 {
  /* width: 100%;
  height: 100%; */
  width: 66px;
  height: auto;
  object-fit: cover; /* 'contain' can be used if you want to fit the whole image */
}

.profile-unknow-img{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}
.photo-img{
  position: absolute;
  bottom: -5px; /* Adjust the position as needed */
  right: -5px;
}
.upload-text{
  margin-left: 15px;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.address-card{
  width: 100%;
  border: 1px solid #D4D4D4;
  background-color: #FBFBFB;
  min-height: 93px;
  height: auto;
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
}
.address-card-name{
  width: 100%;
  color: #344054;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter', sans-serif;
  
}
.address-card-text{
  color: #878787;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.address-save-btn{
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  background-color: #144936;
  color: #effbf7;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.default-text{
  color: #FF0000;
  font-weight: 600;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.disabled-area {
  pointer-events: none;
  color: gray;
  opacity: 50%;
  /* Any other styles you want to apply to disabled links */
}
