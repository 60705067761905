.vh-100 {
    height: 100vh;
}
.main-div{
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center ;
    padding: 15px;
}

.set-password-text{
    font-size: 18px;
    font-weight: 600;
    color: #144936;
    font-family: 'Poppins', sans-serif;
}
.description-text{
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #878787;
    margin: 10px 0 10px 0;
}
.password-label-div-text {
    width: 100%;
    color: #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    margin: 15px 0 10px 0;
    /* 142.857% */
  }
  .password-div-type {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--White, #fff);
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 5px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .password-div-type-input {
    width: 95%;
    border: none;
    outline: none;
    align-items: start;
    text-align: left;
  }
  .sign-in-button{
    width: 100%;
    height: 44px;
    border-radius: 8px;
    background-color: #071614;
    box-shadow: 0px 1px 2px 0px #1018280D;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin: 25px 0 10px 0;
  }
  .errors{
    width: 100%;
    color: #FF0000;
    font-weight: 400;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    text-align: start;
  }